var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", { staticClass: "app-welcome-message d-flex" }, [
        !_setup.isAddingMasterMode && !_setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Details van koppeling met partij")])
          : _vm._e(),
        _setup.isAddingMasterMode
          ? _c("h2", [_vm._v("Toevoegen van koppeling met partij")])
          : _vm._e(),
        _setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Toevoegen van koppeling met partij versie")])
          : _vm._e(),
        _c("h2", { class: { loadingDots: !_setup.allDataLoaded } }),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _setup.hasDetails
                        ? _c("v-col", { attrs: { cols: "auto" } }, [
                            _vm._v(" Tijdlijn: "),
                          ])
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                group: "",
                                mandatory: "",
                                color: "blue accent-3",
                              },
                              model: {
                                value: _setup.selectedTabIndex,
                                callback: function ($$v) {
                                  _setup.selectedTabIndex = $$v
                                },
                                expression: "selectedTabIndex",
                              },
                            },
                            _vm._l(_setup.detailTabs(), function (item, index) {
                              return _c(
                                "v-btn",
                                {
                                  key: index,
                                  staticClass: "v-application elevation-2",
                                  attrs: {
                                    value: index,
                                    disabled: !_setup.isReadOnly,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                _setup.ValidationObserver,
                { ref: "obs" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Geldigheid")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig vanaf",
                                          rules: "date_between|required",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig vanaf",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                    rules: "required",
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valFrom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valFrom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valFrom",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig tot",
                                          rules: `date_between|date_after:${_setup.selectedData.valFrom}`,
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig tot",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valTill,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valTill",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valTill",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: {
                                noBorders:
                                  !_setup.isEditDataMode &&
                                  !_setup.isEditTLMode,
                              },
                            },
                            [
                              _c("header", [_vm._v("Aanpassing")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aanpassings omschrijving",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Omschrijving",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.allowEdit ||
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .modDescr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "modDescr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.modDescr",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_setup.isAddingMasterMode &&
                              !_setup.isAddingDetailMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Door",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.selectedData.modBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "modBy",
                                                  $$v
                                                )
                                              },
                                              expression: "selectedData.modBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_setup.isAddingMasterMode &&
                              !_setup.isAddingDetailMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Datum",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.modDateFormatted,
                                              callback: function ($$v) {
                                                _setup.modDateFormatted = $$v
                                              },
                                              expression: "modDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Primaire koppeling")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Party",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Partij",
                                                    "persistent-placeholder":
                                                      "",
                                                    items: _setup.parties,
                                                    "item-text": "name",
                                                    "item-value": "reference",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.isAddingMasterMode,
                                                    readonly:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .partyReference,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "partyReference",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.partyReference",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Address",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Adres",
                                                    "persistent-placeholder":
                                                      "",
                                                    items: _setup.addresses,
                                                    "item-text": "description",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.isAddingMasterMode,
                                                    readonly:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .addressableObjectId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "addressableObjectId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.addressableObjectId",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Toegangsniveau")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Rol",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Rol",
                                                    "persistent-placeholder":
                                                      "",
                                                    items: _setup.partyRoles,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .partyRole,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "partyRole",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.partyRole",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "EuPrecisionType",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Energiedata",
                                                    "persistent-placeholder":
                                                      "",
                                                    items:
                                                      _setup.euPrecisionGroups,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .euPrecisionType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "euPrecisionType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.euPrecisionType",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "EuPowerOfAttorneyType",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Volmacht",
                                                    "persistent-placeholder":
                                                      "",
                                                    items:
                                                      _setup.euPrecisionGroups,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .euPowerOfAttorneyType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "euPowerOfAttorneyType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.euPowerOfAttorneyType",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Secondaire koppelingen")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Installatiegroep",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Installatiegroep",
                                                    "persistent-placeholder":
                                                      "",
                                                    items:
                                                      _setup.installationGroups,
                                                    "item-text": "ref",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .installationGroupId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "installationGroupId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.installationGroupId",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Bron bouwkundige gegevens",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label:
                                                      "Bron bouwkundige gegevens",
                                                    "persistent-placeholder":
                                                      "",
                                                    items:
                                                      _setup.buildingGroups,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .constructionSourceTypeKey,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "constructionSourceTypeKey",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.constructionSourceTypeKey",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Maatregelengroep",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Maatregelengroep",
                                                    "persistent-placeholder":
                                                      "",
                                                    items: _setup.measureGroups,
                                                    "item-text": "reference",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .ecmGroupId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "ecmGroupId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.ecmGroupId",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Contacten")]),
                              _vm._l(_setup.contactList, function (item) {
                                return _c(
                                  "v-row",
                                  { key: item.keyId },
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v(_vm._s(item.partyRoleDescription)),
                                    ]),
                                    _c("v-col", [
                                      _vm._v(_vm._s(item.userDescription)),
                                    ]),
                                    item.valid == false
                                      ? _c("v-col", [_vm._v(" (niet actief) ")])
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "auto" } },
                                      [
                                        _setup.allowEdit
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  small: "",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _setup.delContact(
                                                      item.keyId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-delete ")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _setup.allowEdit
                                ? _c("v-divider", { staticClass: "my-3" })
                                : _vm._e(),
                              _setup.allowEdit
                                ? _c(_setup.ContactRoleSelection, {
                                    on: {
                                      "on-add-contact": _setup.onAddContact,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("details-buttons", {
                        attrs: {
                          addMode: _setup.isAddingMode,
                          editMode: !_setup.isReadOnly,
                          allowSave: !_setup.isSaving,
                        },
                        on: {
                          edit: _setup.setEditMode,
                          "edit-tl": _setup.setEditTLMode,
                          "on-cancel": _setup.onCancel,
                          "add-new-detail": _setup.addNewDetail,
                          "on-cancel-add": function ($event) {
                            return _setup.onCancelAdd()
                          },
                          "save-detail": _setup.saveDetail,
                          "save-new-detail": _setup.saveNewDetail,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }